import Button from '@/components/Button';
import FormGroup, { FormItem } from '@/components/Form/FormGroup';
import Row from '@/components/Grid/Row';
import { H2, H4 } from '@/components/Titles';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import {
  ButtonGroup,
  FormContainer,
  PageTitle,
} from '@/page-blocks/auth/AuthCommon';
import { RouteComponentProps } from '@reach/router';
import { navigate } from 'gatsby';
import React, { FC, useMemo } from 'react';

interface ResultLocationState {
  userId: string;
}

// 아이디찾기 결과
const FindIdResultPage: FC<
  RouteComponentProps<{ location: { state: ResultLocationState } }>
> = ({ location }) => {
  const formattedUserId = useMemo(
    () => (location?.state?.userId || ``).replace(/\w{3}$/g, `***`),
    [location?.state?.userId],
  );
  return (
    <LayoutWithTitle location={location} title="아이디/비밀번호 찾기">
      <FormContainer
        css={`
          margin-top: 96px;
          margin-bottom: 120px;
        `}
      >
        <PageTitle>
          <H2>아이디 찾기 결과입니다</H2>
        </PageTitle>
        <FormItem noBorder>
          <FormGroup>
            <H4>
              회원님의 아이디는{` `}
              <span className="ellipsis">{formattedUserId}</span>
              {` `}
              입니다.
            </H4>
          </FormGroup>
          <ButtonGroup
            css={`
              margin-top: 80px;
            `}
          >
            <Row>
              <Button outline onClick={() => navigate(`/auth/login`)}>
                로그인 화면으로
              </Button>
              <Button onClick={() => navigate(`/auth/find-pw`)}>
                비밀번호 찾기
              </Button>
            </Row>
          </ButtonGroup>
        </FormItem>
      </FormContainer>
    </LayoutWithTitle>
  );
};

export default FindIdResultPage;
