import FindIdResultPage from '@/private-pages/find-id/result';
import { Router } from '@reach/router';
import React, { FC } from 'react';
import FindIdPage from '../../private-pages/find-id';

const FindIdRouter: FC = () => (
  <Router basepath="/auth/find-id">
    <FindIdPage path="/" />
    <FindIdResultPage path="/result" />
  </Router>
);

export default FindIdRouter;
